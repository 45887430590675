<script>
import {
    ArrowUpIcon,
    MailIcon,
    PhoneIcon,
    YoutubeIcon,
    MapPinIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    GithubIcon,
    GitlabIcon
} from 'vue-feather-icons';
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
/**
 * Helpcenter-overview component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Footer,
        ArrowUpIcon,
        MailIcon,
        PhoneIcon,
        MapPinIcon,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        GithubIcon,
        YoutubeIcon,
        GitlabIcon
    }
}
</script>

<template>
<div>
    <Navbar :nav-light="true" />

    <!-- Hero Start -->


    <section
      class="bg-half bg-light d-table w-100"
      style="background: url('images/background/USR_1920.jpg') center center"
    >



    




        <div class="container mt-100 mt-60">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title text-white">How can we support you? </h4>

                    

                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item active" aria-current="page">Support</li>
                                     <li class="breadcrumb-item"><router-link to="/knowledgebase">Knowledgebase</router-link></li>
                                     <li class="breadcrumb-item"><router-link to="/estimate">Estimate</router-link></li>

                                   
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Start Section -->
    <section class="section">




    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->











        <div class="container mt-lg-0">
            <div class="row mt-40 align-items-center mb-4">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-left">
                      <h6 class="my-color-1"> Greetings,</h6>
              <h4 class="title mb-4">Find the support you need</h4>

                        <p class="text-muted mb-0 para-desc"><span class="text-muted">Karukaj DIGITAL </span> offer multiple ways for fast problem resolution and live assistance</p>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="text-center text-md-right">
                        <a href="/knowledgebase" class="btn btn-primary">Knowledgebase Q&A <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

        </div>
        <!--end container-->







           
        











      <div class="container mt-100  mb-4 pb-4 mt-60">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <div class="media features feature-clean">
              <div class="icons my-color-1 text-center mx-auto">
                <i class="uil uil-layer-group d-block rounded h3 mb-0"></i>
              </div>
              <div class="content ml-3">
                <h5 class="mb-1">
                  <a href="https://members.karukaj.net/clients/open_ticket" class="text-dark">Helpdesk tickets</a>
                </h5>
                <p class="text-muted mb-0">
                 For troubleshooting of your most complex issues, we'll suggest to open a support ticket with details
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="media features feature-clean">
              <div class="icons my-color-1 text-center mx-auto">
                <i class="uil uil-bag d-block rounded h3 mb-0"></i>
              </div>
              <div class="content ml-3">
                <h5 class="mb-1">
                  <a href="javascript:void(0)" class="text-dark">Live chat</a>
                </h5>
                <p class="text-muted mb-0">
                  Available 24/7, our live chat system or WhatsApp live Chat is really fast and efficient in real-time.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
            <div class="media features feature-clean">
              <div class="icons my-color-1 text-center mx-auto">
                <i class="uil uil-star d-block rounded h3 mb-0"></i>
              </div>
              <div class="content ml-3">
                <h5 class="mb-1">
                  <a href="javascript:void(0)" class="text-dark">Phone support</a>
                </h5>
                <p class="text-muted mb-0">
                  For issues that can be best addressed over the phone, we provide you an option to call our experienced operator
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
            <div class="media features feature-clean">
              <div class="icons my-color-1 text-center mx-auto">
                <i class="uil uil-at d-block rounded h3 mb-0"></i>
              </div>
              <div class="content ml-3">
                <h5 class="mb-1">
                  <a href="javascript:void(0)" class="text-dark">Suggestions</a>
                </h5>
                <p class="text-muted mb-0">
                  
Our blog may already have an answer to your question, or you may have no idea what we mentioned.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <!--end row-->
      </div>
      <!--end container-->

















      
      <div class="container mt-100 mt-60">
            <div class="row">
              
              <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="media align-items-center shadow rounded p-4 features">
                        <div class="icons m-0 rounded h2 my-color-1 text-center px-3">
                            <i class="uil uil-whatsapp"></i>
                        </div>
                        <div class="content ml-4">
                            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Start WhatsApp Meeting</a></h5>
                            <p class="text-muted mb-0">almost always checked to answer</p>
                            <div class="mt-2">
                                <a title="Chat on WhatsApp Massenger" target="_blank" href="https://wa.me/8801714641541?text=Hi%20mate!" class="btn btn-sm btn-soft-primary">Click to WhatsApp</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-6 col-md-6 col-12">
                    <div class="media align-items-center shadow rounded p-4 features">
                  
                            
                        <div class="icons m-0 rounded h2 my-color-1 text-center px-3">
                            <i class="uil uil-facebook-messenger-alt"></i>
                        </div>
                        <div class="content ml-4">
                            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Facebook Massenger</a></h5>
                            <p class="text-muted mb-0">occasionally checked to answer</p>
                            <div class="mt-2">
                                <a title="Chat on Facebook Massenger" target="_blank" href="https://m.me/karukaj.digital" class="btn btn-sm btn-soft-primary">Click to Chat Now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

            </div>
            <!--end row-->
        </div>
        <!--end container-->












        <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-6 col-12">


            <div class="title-heading mt-5 pt-4">


                <span class="my-color-1 font-weight-bold">Since we are active on our website</span>
                        <h1 class="heading">We're here to support you</h1>
                        
                        <p class="text-muted "><span class="text-muted">Karukaj DIGITAL</span> friendly chat representatives successfully resolve 90%+ of the inquiries one after one. 
                          Usually, we reply within 1 hour on average and in the majority of the cases the issues get resolved with a single reply.

                          <br>
                        
                        </p>

                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <mail-icon class="fea icon-m-md text-dark mr-3"></mail-icon>
                            </div>
                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0">Email</h5>
                                <img src="images/ml.png" alt="">
                            </div>
                        </div>

                        <div class="media contact-detail align-items-center mt-3 ">
                            <div class="icon">
                                <phone-icon class="fea icon-m-md text-dark mr-3"></phone-icon>
                            </div>
                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0">Phone</h5>
                                <a href="tel:+8801714641541" class="text-muted ">+880 1714-641-541</a><br>
                                
                            </div>
                        </div>

                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <map-pin-icon class="fea icon-m-md text-dark mr-3"></map-pin-icon>
                            </div>
                            <div class="media-body content ">
                                 <h5 class="title font-weight-bold mb-0">Location</h5>

                                 7th Floor, Ka 215/B, JS Tower, Merul Badda, Dhaka, BD. <br>

                                <a href="javascript: void(0);" class="video-play-icon text-muted " v-b-modal.modal-1>View on Google map</a>
                            </div>
                            <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-1" size="lg" centered body-class="p-0">
                                <iframe height="500" width="800" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4342.122730387992!2d90.41996321855859!3d23.773204123512432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1643032484214!5m2!1sen!2sbd" style="border:0"></iframe>
                            </b-modal>
                        </div>
                        <ul class="list-unstyled social-icon mb-0 mt-4">
                            <li class="list-inline-item"><a target="_blank" href="https://www.facebook.com/karukaj.digital" class="rounded">
                                    <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="https://www.instagram.com/karukaj.digital" class="rounded">
                                    <instagram-icon class="fea icon-sm fea-social"></instagram-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="https://www.twitter.com/karukaj_digital" class="rounded">
                                    <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="https://www.linkedin.com/company/karukajdigital/" class="rounded">
                                    <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="https://github.com/karukaj/" class="rounded">
                                    <github-icon class="fea icon-sm fea-social"></github-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="javascript:void(0)" class="rounded">
                                    <youtube-icon class="fea icon-sm fea-social"></youtube-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a target="_blank" href="javascript:void(0)" class="rounded">
                                    <gitlab-icon class="fea icon-sm fea-social"></gitlab-icon>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>



                </div>







                <div class="col-lg-5 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="card rounded border-0 shadow-lg ml-lg-5">
                        <div class="card-body">
                            <div class="text-center">




                                <div class="section-title">
                                    


                                  <div class="shape-before">
              <div class="carousel-cell">
                <img
                  src="images/mysvg/support.svg"
                  class="img-fluid mover"
                  alt=""
                />
              </div>

            </div>    
                                      

<a v-b-tooltip.hover title="Click to Chat on WhatsApp Massenger" target="_blank" href="https://wa.me/8801714641541?text=Hi%20mate!" class="text-s underline my-color-1">Chat on WhatsApp</a>



<br>
<span class="text-s"> // or.. request a phone call by submit ..</span>





                                </div>
                            </div>

                            <div class="content">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-12">

                                          <iframe width="100%" height="220" src="https://members.karukaj.net/forms/wtl/d6f2b65fbe46c754b666f9bf50024373" frameborder="0" sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin" allowfullscreen></iframe>
                                       
                                       
                                       
                                        </div>
                                        <!--end col-->

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end container-->







    </section>
    <!--end section-->
    <!-- End Section -->





        <div class="container mt-4 mt-lg-0">
            <div class="row align-items-center mb-4 pb-4">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-left">
              <h4 class="title mb-4">Knowledgebase Support</h4>

                        <p class="text-muted mb-0 para-desc">Drive into our <span class="my-color-1 font-weight-bold">Karukaj Blog</span> and explore the Digital World with few of our writings. 
                     </p>

                 


                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="text-center text-md-right">
                     <br>
                        <a title="Karukaj Blog" target="_blank" href="https://blog.karukaj.net" class="btn btn-soft-primary">Visit Our Blog <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

        </div>
        <!--end container-->





  
  




    
    <section
      class="section mt-100 bg-white pt-0"
      :style="{
        background:
          'url(' +
          require('../../public/images/real/build.png') +
          ') bottom no-repeat',
      }"
    >
  
      
      <div class="container">




        
        <div class="row justify-content-center">
          <div class="col-12 text-center">
       
              <div class="mt-4 pt-2">
                                <h5 class="text-muted">
                  Want to talk now ?<br>
                  <a href="tel:+8801714641541" class="my-color-1 h5"
                    >+880-1714-641-541</a
                  >
                </h5>


  
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--end container-->
    </section>
    <!--end section-->







    <!-- End -->
<div class="position-relative">
        <div class="shape overflow-hidden text-footer">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#4e4e4e"></path>
            </svg>
        </div>
    </div>
    <!--end section-->





  
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-light btn-icon back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>


</template>
